const filterBooking = () => {
    const filter_ooh = document.querySelector('#filter_ooh');
    const filter_booking = document.querySelector('.filter_booking');

    if (filter_ooh && filter_booking) {

            // console.log("Ejecutando filterBooking()");

            const name = document.querySelector('#id_name');
            const initial_date = document.querySelector('#id_initial_date');
            const final_date = document.querySelector('#id_finish_date');
            const director = document.querySelector('#id_commercial_director');
            const campaign = document.querySelector('#id_campaign');
            const state = document.querySelector('#id_state');
            const id_commercial = document.querySelector('#id_commercial');

            // !Obtener los valores de los query params
            const urlParams = new URLSearchParams(window.location.search);
            const name_url_param = urlParams.get('name');
            const initial_date_url_param = urlParams.get('initial_date');
            const final_date_url_param = urlParams.get('finish_date');
            const director_url_param = urlParams.get('commercial_director');
            const campaign_url_param = urlParams.get('campaign');
            const state_url_param = urlParams.get('state');
            const commercial_url_param = urlParams.get('commercial');

            const filters_container = document.querySelector('.filters_container');
            const remove_filters_button = document.querySelector('#clean_filters');

            // Funcionalidad para remover filtros
            remove_filters.addEventListener('click', (e) => {
                e.preventDefault();
                const url = remove_filters.getAttribute('data-url');
                window.location.href = url;
            });

            // Funcionalidad para limpiar filtros
            clean_filters.addEventListener('click', (e) => {
                e.preventDefault();
                //dar click al botón de remover filtros
                remove_filters.click();
            });


            function toggleRemoveFiltersButton() {
                const filters = filters_container.querySelectorAll('.filter');
                const isFiltersActive = filters.length > 0;
                
                if (isFiltersActive) {
                    remove_filters_button.classList.remove('d-none');
                } else {
                    remove_filters_button.classList.add('d-none');
                }
            }

            function addFilter(filterClass, value) {
                const previousFilter = filters_container.querySelector(`.filter.${filterClass}`);
                if (previousFilter) {
                    previousFilter.remove();
                }
        
                if (value && value.trim() !== '') {
                    const filter = document.createElement('div');
                    filter.classList.add('filter');
                    filter.textContent = value;
                    filter.classList.add(filterClass); // Agregar clase específica
                    filters_container.appendChild(filter);
                }
        
                // Llamar a la función para mostrar u ocultar el botón "remove_filters"
                toggleRemoveFiltersButton();
            }


            const filtersBoxFunction = () => {

                //! Al cambiar name se añade el filtro
                name.addEventListener('input', () => {
                    // Validar si filterName ya existe
                    const previousFilter = filters_container.querySelector(`.filter.filter_name`);
                    if (previousFilter) {
                        previousFilter.remove();
                    }
            
                    // Crear el filtro de nombre
                    const filterName = document.createElement('div');
                    filterName.classList.add('filter', 'filter_name', 'd-none');
                    filters_container.appendChild(filterName);
            
                    const inputValue = name.value;
                    filterName.textContent = "Nombre";
            
                    if (inputValue.trim() === '') {
                        filterName.classList.add('d-none');
                    } else {
                        filterName.classList.remove('d-none');
                    }

                });
                
                //! Al cambiar initial_date se añade el filtro
                initial_date.addEventListener('input', () => {
                    addFilter('initial_date', "Fecha inicial");
                });

                //! Al cambiar final_date se añade el filtro
                final_date.addEventListener('input', () => {
                    addFilter('final_date', "Fecha final");
                });


                //! Al cambiar director se añade el filtro
                director?.addEventListener('change', () => {
                    addFilter('director', "Director comercial");
                });

                //! Al cambiar campaign se añade el filtro
                campaign.addEventListener('change', () => {
                    addFilter('campaign', "Campaña");
                });

                //! Al cambiar state se añade el filtro
                state.addEventListener('change', () => {
                    addFilter('state', "Estado de reserva");
                });

                //! Al cambiar commercial se añade el filtro
                id_commercial.addEventListener('change', () => {
                    addFilter('commercial', "Comercial");
                });

            }

            const queryParamsFilterBox = () => {
            // Mostrar los filtros si hay parámetros de consulta en la URL
                if (name_url_param) {
                    const filterName = document.createElement('div');
                    filterName.classList.add('filter', 'filter_name');
                    filterName.textContent = "Nombre";
                    filters_container.appendChild(filterName);
                }

                if (initial_date_url_param) {
                    addFilter('initial_date', "Fecha inicial");
                }

                if (final_date_url_param) {
                    addFilter('final_date', "Fecha final");
                }

                if (director_url_param) {
                    addFilter('director', "Director comercial");
                }

                if (campaign_url_param) {
                    addFilter('campaign', "Campaña");
                }

                if (state_url_param) {
                    addFilter('state', "Estado de reserva");
                }

                if (commercial_url_param) {
                    addFilter('commercial', "Comercial");
                }
            }


            filtersBoxFunction();
            queryParamsFilterBox();
            toggleRemoveFiltersButton();
        
    }
};

export default filterBooking;
